import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import UserResetPasswordModal from "../Modal/UserResetPasswordModal";
import { useDispatch } from "react-redux";
import { deleteUser } from "app/redux/actions/user";
import useAlert from "app/hooks/useAlert";
import ConfirmModal from "../ConfirmModal";
import moment from "moment";

const UserTable = ({ dataSource }) => {
  const isXsMatch = useMediaQuery(theme => theme.breakpoints.down("md"));

  const { showSuccessAlert, showErrorAlert } = useAlert();

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [userId, setUserId] = useState(null);

  const dispatch = useDispatch();

  const handleDeleteOpen = id => {
    setIsDeleteOpen(true);
    setUserId(id);
  };

  const handleDeleteClose = () => {
    setIsDeleteOpen(false);
    setUserId(null);
  };

  const handleDeleteConfirm = () => {
    console.log(userId);
    const success = dispatch(deleteUser(userId));
    if (success) showSuccessAlert("刪除成功");
    else showErrorAlert("刪除失敗");
    handleDeleteClose();
  };

  const formatEmail = email => {
    if (isXsMatch) return email.slice(0, 14) + "...";
    return email;
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>最後登入時間</TableCell>
              <TableCell>修改密碼</TableCell>
              <TableCell>刪除</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource.map(user => (
              <TableRow
                key={user.get("id")}
                sx={{
                  "&:hover": { cursor: "pointer", backgroundColor: "#f5f5f5" },
                }}>
                <TableCell width="40%">
                  {formatEmail(user.get("email"))}
                </TableCell>
                <TableCell width="40%">
                  {user.get("last_login") &&
                    moment
                      .unix(user.get("last_login"))
                      .format("YYYY-MM-DD HH:mm")}
                </TableCell>
                <TableCell width="10%">
                  <UserResetPasswordModal user={user} />
                </TableCell>
                <TableCell width="10%">
                  <IconButton onClick={() => handleDeleteOpen(user.get("id"))}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModal
        isOpen={isDeleteOpen}
        handleClose={handleDeleteClose}
        handleSubmit={handleDeleteConfirm}
        title="刪除使用者"
        content="確定要刪除使用者嗎？"
      />
    </>
  );
};

export default UserTable;
