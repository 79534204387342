import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import Div from "@jumbo/shared/Div";
import NoteList from "app/shared/NoteList";
import { useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const Home = () => {
  const { setJumboLayoutOptions } = useJumboLayout();
  const smMatch = useMediaQuery("(max-width: 959px)");

  const noteData = useSelector(({ note }) => note);
  const notes = useSelector(({ search }) => search.getIn(["results", "notes"]));

  useEffect(() => {
    setJumboLayoutOptions({
      sidebar: { open: !smMatch },
      footer: { hide: !smMatch },
    });
  }, [smMatch]);

  return (
    <Div id="content" sx={{ px: { md: 6 }, py: 2 }}>
      <NoteList
        noteList={notes}
        selectedNotes={noteData.get("selectedNotes")}
      />
    </Div>
  );
};

export default Home;
