import Immutable from "immutable";
import {
  CREATE_USER_DONE,
  DELETE_USER_DONE,
  GET_USER_DONE,
  SEARCH_USER,
  UPDATE_USER_DONE,
} from "../types/user";

const INIT_STATE = Immutable.OrderedMap({
  results: null,
  originalResults: null,
});

const userReducer = (_ = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_DONE: {
      return _.set("results", Immutable.fromJS(payload.doc)).set(
        "originalResults",
        Immutable.fromJS(payload.doc)
      );
    }
    case CREATE_USER_DONE: {
      return _.set(
        "results",
        _.get("results").push(Immutable.fromJS(payload.doc))
      );
    }
    case UPDATE_USER_DONE: {
      const results = _.get("results");
      if (!results) return _;
      const newResults = results.map(user =>
        user.get("id") === payload.id
          ? user.merge(Immutable.fromJS(payload.doc))
          : user
      );
      return _.set("results", newResults).set("originalResults", newResults);
    }
    case DELETE_USER_DONE: {
      const results = _.get("results");
      if (!results) return _;
      const newResults = results.filter(user => user.get("id") !== payload.id);
      console.log(newResults);
      return _.set("results", newResults).set("originalResults", newResults);
    }
    case SEARCH_USER: {
      const originalResults = _.get("originalResults");
      if (!originalResults) return _;

      if (!payload.email) {
        return _.set("results", originalResults);
      }

      const newResults = originalResults.filter(user =>
        user.get("email").toLowerCase().includes(payload.email.toLowerCase())
      );
      return _.set("results", newResults);
    }
    default:
      return _;
  }
};

export default userReducer;
