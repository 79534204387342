export const GET_USER_LIST = "USER/GET_LIST";
export const GET_USER_DONE = "USER/GET_LIST_DONE";

export const CREATE_USER = "USER/CREATE";
export const CREATE_USER_DONE = "USER/CREATE_DONE";

export const RESET_PASSWORD = "USER/RESET_PASSWORD";
export const RESET_PASSWORD_DONE = "USER/RESET_PASSWORD_DONE";

export const UPDATE_USER = "USER/UPDATE";
export const UPDATE_USER_DONE = "USER/UPDATE_DONE";

export const DELETE_USER = "USER/DELETE";
export const DELETE_USER_DONE = "USER/DELETE_DONE";

export const SEARCH_USER = "USER/SEARCH";
