import { generateHTML, generateJSON } from "@tiptap/core";
import Color from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Image from "@tiptap/extension-image";
import TLink from "@tiptap/extension-link";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Paragraph from "@tiptap/extension-paragraph";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import TurndownService from "turndown";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import ListItem from "@tiptap/extension-list-item";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Blockquote from "@tiptap/extension-blockquote";
import CodeBlock from "@tiptap/extension-code-block";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import Code from "@tiptap/extension-code";

const genListType = [
  Document,
  Color,
  Highlight,
  HorizontalRule.configure({
    HTMLAttributes: { class: "novel-editor-hr" },
  }),
  Image.configure({ HTMLAttributes: { class: "novel-editor-image" } }),
  TLink,
  TaskItem,
  TaskList,
  TextStyle,
  Underline,
  Paragraph.configure({ HTMLAttributes: { class: "novel-editor-p" } }),
  Text,
  Bold,
  Italic,
  Strike,
  ListItem,
  BulletList,
  OrderedList,
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  Code,
];

export const genHTML = (note, view) => {
  const contentStr = getNoteContent(note, view);

  // if contentStr is html, return it
  if (testHtml(contentStr)) {
    return contentStr;
  }

  // init jsonObj
  const jsonObj = {
    type: "doc",
    content: [],
  };

  // if contentStr is empty, add a paragraph with text "目前沒有任何內容"
  if (!contentStr) {
    jsonObj.content.push({
      type: "paragraph",
      content: [{ text: " ", type: "text" }],
    });

    return generateHTML(jsonObj, genListType);
  }

  // if contentStr is a valid JSON, use it
  const jsonData =
    contentStr.includes('"type":"doc"') && JSON.parse(note[view]);
  const isJSON = jsonData.hasOwnProperty("type") && jsonData.type === "doc";

  if (!testHtml(contentStr) && !isJSON) {
    return stringToHtml(contentStr);
  }

  JSON.parse(contentStr).content.forEach(c => {
    jsonObj.content.push(c);
  });

  // if note type is image amd is not in the content, add it
  const foundItem = jsonObj.content.find(
    item =>
      item.type === "image" && item.attrs && item.attrs.src === note.fileurl
  );

  if (note.source.type === "image" && !foundItem) {
    const imageObj = {
      type: "image",
      attrs: {
        src: note.fileurl,
        alt: "image",
        title: null,
        HTMLAttributes: {
          class: "novel-editor-image",
        },
      },
    };

    jsonObj.content.push(imageObj);
  }

  return generateHTML(jsonObj, genListType);
};

export const genContent = (note, type) => {
  if (!type) return;

  let content = getNoteContent(note, type);

  if (!content) return "";

  if (!testHtml(content)) {
    content = stringToHtml(content);
    if (note.source?.type === "image") {
      content += `<img src="${note.fileurl}" alt="Note Image" />`;
    }
  }

  const jsonContent = generateJSON(content, genListType);

  const foundItem = jsonContent.content.find(
    item =>
      item.type === "image" && item.attrs && item.attrs.src === note.fileurl
  );

  if (note.source.type === "image" && !foundItem) {
    const imageObj = {
      type: "image",
      attrs: {
        src: note.fileurl,
        alt: "image",
        title: null,
        HTMLAttributes: {
          class: "novel-editor-image",
        },
      },
    };

    jsonContent.content.push(imageObj);
  }

  return jsonContent;
};

export const genTxt = (note, view) => {
  const content = getNoteContent(note, view);
  const file = new Blob([content], {
    type: "text/plain;charset=utf-8",
  });
  const url = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = "note.txt";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const genMarkDown = (note, view) => {
  const turndownService = new TurndownService({
    bulletListMarker: "-",
    emDelimiter: "_",
  });
  turndownService.addRule("escapeRule", {
    filter: ["p"],
    replacement: content => content.replace(/(\d+)\\\./, "$1."),
  });

  const content = getNoteContent(note, view);

  const url = `${window.location.origin}${note.fileurl}`;
  const isHtml = testHtml(content);

  let html = isHtml ? content : stringToHtml(content);

  if (note.source?.type === "image") {
    const imageUrl = `<img src="${url}" alt="Note Image" />`;
    html += imageUrl;
  }

  // Try to parse as JSON only if it looks like JSON
  if (isHtml && content.trim().startsWith("{")) {
    try {
      const json = JSON.parse(content);
      html = generateHTML(json, genListType);
    } catch (error) {
      // If JSON parsing fails, use the HTML content as is
      console.warn("Failed to parse JSON:", error);
    }
  }

  return turndownService.turndown(html);
};

export const stringToHtml = str => {
  // Remove special tags
  str = str.replace(/<\/?[^>]+(>|$)/g, "");

  // Normalize line endings (convert \r\n to \n)
  str = str.replace(/\r\n/g, "\n");

  // Replace multiple consecutive newlines with a single \n
  str = str.replace(/\n\s*\n/g, "\n");

  // Split into paragraphs
  const paragraphs = str
    .split("\n")
    .map(p => p.trim())
    .filter(p => p.length > 0);

  // Wrap each paragraph in <p> tags
  const html = paragraphs.map(p => `<p>${p}</p>`).join("\n");

  return html;
};

const testHtml = str => {
  // 定義常見的 HTML 標籤
  const commonHtmlTags = [
    "a",
    "abbr",
    "article",
    "aside",
    "b",
    "blockquote",
    "body",
    "br",
    "button",
    "caption",
    "code",
    "col",
    "colgroup",
    "div",
    "dl",
    "dt",
    "dd",
    "em",
    "fieldset",
    "figure",
    "footer",
    "form",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "head",
    "header",
    "hr",
    "html",
    "i",
    "iframe",
    "img",
    "input",
    "label",
    "legend",
    "li",
    "link",
    "main",
    "meta",
    "nav",
    "ol",
    "option",
    "p",
    "pre",
    "script",
    "section",
    "select",
    "small",
    "span",
    "strong",
    "style",
    "table",
    "tbody",
    "td",
    "textarea",
    "tfoot",
    "th",
    "thead",
    "tr",
    "ul",
  ].join("|");

  const regex = new RegExp(`<(${commonHtmlTags})[^>]*>(.*?)<\\1>`, "gi");

  return regex.test(str);
};

export const getNoteContent = (note, view) => {
  if (view === "content" || view === "translate" || view === "summary") {
    return note[view];
  }
  return note.generated[view.split("-")[1]]?.content;
};
