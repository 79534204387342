import React from 'react';
import { Helmet } from 'react-helmet';

const MetaHelmet = ({ title, description, keywords, imageUrl }) => {
  const metaTitle = title
    ? `AIMochi - ${title}`
    : 'AIMochi - 你的 AI 筆記小精靈';
  const metaDescription = trimMetaDescription(description);
  const lang = languageTest(description);

  const metaKeywords = keywords
    ? keywords.join(', ')
    : 'AIMochi, 語音編輯器, 語音筆記, 語音記錄, 語音轉文字, 語音轉文字筆記, 語音轉文字記錄';

  const metaImage = imageUrl || '/images/logo-main.png';

  return (
    <Helmet>
      <title>{metaTitle}</title>

      <meta name='msapplication-TileColor' content='#6200EE' />
      <meta name='msapplication-TileImage' content={metaImage} />
      <link rel='icon' href={metaImage} />
      <link rel='stylesheet' href='/fonts/noir-pro/styles.css' />

      {/* Add language meta tag */}
      <meta httpEquiv='content-language' content={lang} />

      {/* Add canonical URL */}
      <link
        rel='canonical'
        href={`https://aimochi.com${window.location.pathname}`}
      />

      <meta name='title' content={metaTitle} />
      <meta name='keywords' content={metaKeywords} />
      <meta name='theme-color' content='#6200EE' />
      <meta name='description' content={metaDescription} />

      {/* Enhance Open Graph tags */}
      <meta property='og:type' content='website' />
      <meta
        property='og:url'
        content={`https://aimochi.com${window.location.pathname}`}
      />
      <meta property='og:description' content={metaDescription} />
      <meta property='og:title' content={metaTitle} />
      <meta property='og:image' content={metaImage} />
      <meta property='og:site_name' content='AIMochi' />

      {/* Enhance Twitter tags */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:image' content={metaImage} />
      <meta name='twitter:site' content='@AIMochi' />
      <meta name='twitter:title' content={metaTitle} />
      <meta name='twitter:description' content={metaDescription} />

      {/* Add Apple touch icon */}
      <link rel='apple-touch-icon' href='/images/logo-main.png' />

      <script type='application/ld+json'>
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "AIMochi",
            "url": "https://aimochi.com",
            "description": "${metaDescription}"
          }
        `}
      </script>
    </Helmet>
  );
};

export default MetaHelmet;

const languageTest = str => {
  const patterns = {
    Chinese: /^[\u4E00-\u9FA5]+$/,
    Japanese: /^[\u3040-\u30FF]+$/,
    Korean: /^[\uAC00-\uD7AF]+$/,
    English: /^[A-Za-z]+$/,
  };

  for (const [language, pattern] of Object.entries(patterns)) {
    if (pattern.test(str)) {
      switch (language) {
        case 'Chinese':
          return 'zh-TW';
        case 'Japanese':
          return 'ja';
        case 'Korean':
          return 'ko';
        case 'English':
          return 'en';
        default:
          return 'zh-TW';
      }
    }
  }
  return 'zh-TW'; // Default to Chinese if no match
};

function trimMetaDescription(str) {
  if (!str) {
    return '歡迎使用AIMochi！這是所有記憶魔法開始的地方。AIMochi是一個創新的AI助手，它可以協助您記錄各種資料，無論是文字、連結、語音、檔案還是圖片，只需透過Line傳給AIMochi，它就像是您的第二個腦，可以幫助您輕鬆記錄並隨時檢索筆記。';
  }

  const lang = languageTest(str);
  const maxLength = {
    'zh-TW': 70,
    ja: 70,
    ko: 120,
    en: 160,
  };

  return str.slice(0, maxLength[lang] || 70);
}
