import moment from "moment";
import {
  CREATE_USER,
  CREATE_USER_DONE,
  DELETE_USER,
  DELETE_USER_DONE,
  GET_USER_DONE,
  GET_USER_LIST,
  RESET_PASSWORD,
  RESET_PASSWORD_DONE,
  SEARCH_USER,
} from "../types/user";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "app/services";

export const getUserList = () => {
  return async dispatch => {
    const url = "/api/v1/users";
    dispatch({ type: GET_USER_LIST });

    const response = await getRequest(url);

    dispatch({ type: GET_USER_DONE, payload: { doc: response.data } });
  };
};

export const createUser = formData => {
  return async dispatch => {
    dispatch({ type: CREATE_USER });

    const url = "/api/v1/users";

    const response = await postRequest(url, formData);

    if (!response.data.success) return;

    const doc = {
      ...formData,
      id: response.data.payload.id,
      provider: "email",
      created_at: moment().unix() / 1000,
    };

    dispatch({ type: CREATE_USER_DONE, payload: { doc } });
  };
};

export const resetPassword = (id, formData) => {
  return async dispatch => {
    dispatch({ type: RESET_PASSWORD });

    const url = `/api/v1/users/${id}`;

    const response = await patchRequest(url, formData);

    if (!response.data.success) return response.data.success;

    dispatch({ type: RESET_PASSWORD_DONE });
    return response.data.success;
  };
};

export const deleteUser = id => {
  return async dispatch => {
    dispatch({ type: DELETE_USER });

    const url = `/api/v1/users/${id}`;

    const response = await deleteRequest(url);

    if (!response.data.success) return;

    dispatch({ type: DELETE_USER_DONE, payload: { id } });
  };
};

export const searchUser = email => {
  return async dispatch => {
    dispatch({ type: SEARCH_USER, payload: { email } });
  };
};
