import baseAxios from 'axios';

const axios = baseAxios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

const abortControllers = new Map();

export const getRequest = endPoint => {
  const tokenStr = localStorage.getItem('AIMochi');
  const token = JSON.parse(tokenStr)?.token;

  const config = {
    headers: {
      authorization: token,
      'Cache-Control': 'no-cache',
    },
  };

  try {
    const res = axios.get(endPoint, config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const postRequest = (endPoint, params) => {
  const tokenStr = localStorage.getItem('AIMochi');
  const token = JSON.parse(tokenStr)?.token;

  const config = {
    headers: {
      authorization: token,
      'Content-Type': 'application/json',
    },
  };

  if (endPoint.includes('files')) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  try {
    const res = axios.post(endPoint, params, config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const patchRequest = (endPoint, params) => {
  const tokenStr = localStorage.getItem('AIMochi');
  const token = JSON.parse(tokenStr)?.token;

  const config = {
    headers: {
      authorization: token,
    },
  };

  try {
    const res = axios.patch(endPoint, params, config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteRequest = endPoint => {
  const tokenStr = localStorage.getItem('AIMochi');
  const token = JSON.parse(tokenStr)?.token;

  const config = {
    headers: {
      authorization: token,
    },
  };
  try {
    const res = axios.delete(endPoint, config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const streamRequest = async (
  url,
  data,
  onStreamData,
  signalKey = 'default'
) => {
  const storage = localStorage.getItem('AIMochi');
  if (!storage) return;
  const token = JSON.parse(storage).token;
  const controller = new AbortController();
  abortControllers.set(signalKey, controller);
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token,
      },
      body: JSON.stringify(data),
      signal: controller.signal,
      stream: true,
    });

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value, { stream: true });
      onStreamData(chunk);
    }
  } catch (error) {
    console.error(error);
  }
};

export const stopStream = async (signalKey = 'default') => {
  const controller = abortControllers.get(signalKey);
  if (controller) {
    controller.abort();
    abortControllers.delete(signalKey);
  }
};
