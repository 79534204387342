import React, { memo, useMemo } from 'react';
import { Editor as NovelEditor } from '@liuanboy/novel';
import './styles.css';
import Div from '@jumbo/shared/Div';

const ContentEditor = ({
  noteId,
  debounceDuration = 750,
  className = 'novel-editor',
  locales,
  onContentDurationChange,
  noteView,
  content,
}) => {
  // 優化 editorKey 的生成
  const editorKey = useMemo(() => `${noteId}-${noteView}`, [noteId, noteView]);

  if (noteView === 'translate' && !content) {
    return <Div sx={{ p: 6, pt: 3 }}>翻譯中...</Div>;
  }

  return (
    <NovelEditor
      key={editorKey}
      className={className}
      storageKey={noteId}
      defaultValue={content}
      onDebouncedUpdate={onContentDurationChange}
      debounceDuration={debounceDuration}
      disableLocalStorage={true}
      locales={locales}
    />
  );
};

export default memo(ContentEditor, (prevProps, nextProps) => {
  return (
    prevProps.noteId === nextProps.noteId &&
    prevProps.className === nextProps.className &&
    prevProps.debounceDuration === nextProps.debounceDuration &&
    prevProps.locales === nextProps.locales &&
    prevProps.onContentDurationChange === nextProps.onContentDurationChange &&
    prevProps.content === nextProps.content
  );
});
