export const aiGenTypes = [
  { label: '報告摘要', value: 'executive_summary' },
  { label: '會議記錄', value: 'meeting_note' },
  { label: '新聞稿', value: 'press_release' },
  { label: '信件', value: 'email' },
  { label: '社群媒體貼文(Thread)', value: 'thread' },
  { label: '問答(Q&A)', value: 'qa' },
  { label: '部落格', value: 'blog' },
  { label: '操作說明', value: 'manual' },
  { label: '行動計劃', value: 'action_plan' },
  { label: '心得筆記', value: 'reflection' },
];