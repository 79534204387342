import {
  Autocomplete,
  Box,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import LabelIcon from '@mui/icons-material/Label';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';

const LabelSelector = ({
  labelList,
  handleUpdate,
  selectedLabel,
  handleDelModalOpen,
}) => {
  const { t } = useTranslation();

  if (!labelList) return null;

  const value = labelList.filter(item => selectedLabel.includes(item.id));

  return (
    <Grid item container md={12} xs={12} sx={{ px: 2, py: '2px' }}>
      <Grid
        item
        xs={2.6}
        md={1.2}
        lg={1.1}
        xl={0.73}
        sx={{ display: 'flex', alignItems: 'center', minWidth: 94 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}>
          <LabelIcon
            sx={{
              fontSize: 18,
              mr: 0.5,
            }}
          />
          <Typography variant='subtitle2'>{t('NOTE_LABELS')}</Typography>
        </Box>
      </Grid>
      <Grid item xs={8.56} md={10.2} lg={10.9} xl={10.8}>
        <Autocomplete
          sx={{ width: '100%' }}
          multiple
          fullWidth
          options={labelList}
          value={value}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={option => option.name}
          onChange={(e, value) => handleUpdate(e, value)}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option.id}
                variant='outlined'
                label={option?.name}
                color='primary'
                size='small'
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              variant='filled'
              placeholder={t('NOTE_LABELS_ADD')}
              InputProps={{
                ...params.InputProps,
                style: {
                  padding: 0,
                  backgroundColor: '#F5F7FA',
                },
                disableUnderline: true,
              }}
            />
          )}
          // 刪除標籤 Node

          renderOption={(props, option) => (
            <Box
              component='li'
              {...props}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <Typography variant='body2' sx={{ width: 'inherit' }}>
                {option.name}
              </Typography>
              {/* <IconButton onClick={e => handleDelModalOpen(e, option)}>
                <DeleteIcon />
              </IconButton> */}
            </Box>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default memo(LabelSelector);
