import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer from "./auth";
import noteReducer from "./note";
import labelReducer from "./label";
import searchReducer from "./search";
import chatgptReducer from "./chat";
import subscriptionReducer from "./subscription";
import statusReducer from "./status";
import userReducer from "./user";

const exportReducers = history => {
  return combineReducers({
    router: connectRouter(history),
    app: authReducer,
    note: noteReducer,
    label: labelReducer,
    search: searchReducer,
    chatgpt: chatgptReducer,
    subscription: subscriptionReducer,
    status: statusReducer,
    user: userReducer,
  });
};

export default exportReducers;
