import { genHTML, genMarkDown, genTxt } from "app/utils/downloadDoc";
import * as __ from "../types/note";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  stopStream,
  streamRequest,
} from "app/services";

export const selNotes = (id, checked) => {
  return { type: __.SEL_NOTE, payload: { id, checked } };
};

export const clearNotes = () => {
  return { type: __.CLEAR_NOTES };
};

export const createNote = (uId, formData) => {
  const url = `/api/v1/users/${uId}/notes`;

  return async dispatch => {
    try {
      dispatch({ type: __.CREATE_NOTE });
      const res = await postRequest(url, formData);
      const data = await res.data;
      return dispatch(createNoteDone(data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const createNoteDone = doc => {
  return dispatch => {
    dispatch({ type: __.CREATE_NOTE_DONE, payload: doc });
    return doc.payload.id;
  };
};

export const updNoteContent = (uid, nid, content, field) => {
  if (!content) return;

  const url = `/api/v1/users/${uid}/notes/${nid}`;

  const body = {};

  body[field] = content;

  return async dispatch => {
    try {
      dispatch({ type: __.UPDATE_NOTE_CONTENT });
      await patchRequest(url, body);
      return dispatch(updNoteContentDone(nid, body));
    } catch (error) {
      console.log(error);
    }
  };
};

export const updNoteContentDone = (nid, doc) => {
  return dispatch => {
    dispatch({ type: __.UPDATE_NOTE_CONTENT_DONE, payload: { nid, doc } });
    setTimeout(() => {
      dispatch({ type: __.CLEAR_STATUS });
    }, 3000);
  };
};

export const updNoteLabel = (uid, nid, labels) => {
  const url = `/api/v1/users/${uid}/notes/${nid}`;

  const body = {
    labels,
  };

  return async dispatch => {
    try {
      dispatch({ type: __.UPDATE_NOTE_LABEL });
      await patchRequest(url, body);
      return dispatch(updNoteLabelDone(nid, labels));
    } catch (error) {
      console.log(error);
    }
  };
};

export const updNoteLabelDone = (nid, labels) => {
  return dispatch => {
    dispatch({ type: __.UPDATE_NOTE_LABEL_DONE, payload: { nid, labels } });
    setTimeout(() => {
      dispatch({ type: __.CLEAR_STATUS });
    }, 3000);
  };
};

export const updNoteTitle = (uid, nid, title) => {
  const url = `/api/v1/users/${uid}/notes/${nid}`;

  const body = {
    title,
  };

  return async dispatch => {
    try {
      dispatch({ type: __.UPDATE_NOTE_TITLE });
      await patchRequest(url, body);
      console.log("update");
      return dispatch(updNoteTitleDone(nid, title));
    } catch (error) {
      console.log(error);
    }
  };
};

export const updNoteTitleDone = (nid, title) => {
  return dispatch => {
    dispatch({ type: __.UPDATE_NOTE_TITLE_DONE, payload: { nid, title } });
    setTimeout(() => {
      dispatch({ type: __.CLEAR_STATUS });
    }, 3000);
  };
};

export const updNoteURL = (uid, nid, _url) => {
  const url = `/api/v1/users/${uid}/notes/${nid}`;

  const body = {
    url: _url,
  };

  return async dispatch => {
    try {
      dispatch({ type: __.UPDATE_NOTE_URL });
      await patchRequest(url, body);
      return dispatch(updNoteTitleDone(nid, _url));
    } catch (error) {
      console.log(error);
    }
  };
};

export const updNoteURLDone = (nid, _url) => {
  return dispatch => {
    dispatch({ type: __.UPDATE_NOTE_URL_DONE, payload: { nid, _url } });
    setTimeout(() => {
      dispatch({ type: __.CLEAR_STATUS });
    }, 3000);
  };
};

export const delNote = (uid, nid) => {
  const url = `/api/v1/users/${uid}/notes/${nid}`;

  return async dispatch => {
    try {
      dispatch({ type: __.DEL_NOTE });
      await deleteRequest(url);
      return dispatch(delNoteDone(nid));
    } catch (error) {
      console.log(error);
    }
  };
};

export const delNoteDone = nid => {
  return { type: __.DEL_NOTE_DONE, payload: nid };
};

export const delBatchNotes = (uId, ids) => {
  return async dispatch => {
    ids.forEach(async id => {
      const url = `/api/v1/users/${uId}/notes/${id}`;
      dispatch({ type: __.DEL_BATCH_NOTE });
      await deleteRequest(url);
    });
    return dispatch(delBatchNotesDone(ids));
  };
};

export const delBatchNotesDone = ids => {
  return { type: __.DEL_BATCH_NOTE_DONE, payload: { ids } };
};

export const delNoteLabel = (uid, lId) => {
  const url = `/users/${uid}/labels/${lId}`;

  return async dispatch => {
    try {
      dispatch({ type: __.DEL_NOTE_LABEL });
      await deleteRequest(url);
      return dispatch(delNoteLabelDone(lId));
    } catch (error) {
      console.log(error);
    }
  };
};

export const delNoteLabelDone = lId => {
  return { type: __.DEL_NOTE_LABEL_DONE, payload: { lId } };
};

export const cngListView = type => {
  return { type: __.CNG_LIST_VIEW, payload: { listView: type } };
};

export const updNotePassword = (uid, nid, password, permission) => {
  const url = `/api/v1/users/${uid}/notes/${nid}`;

  const body = {
    password,
    permission,
  };

  return async dispatch => {
    try {
      dispatch({ type: __.UPDATE_NOTE_PASSWORD });
      await patchRequest(url, body);
      return dispatch(updNotePasswordDone(permission));
    } catch (error) {
      console.log(error);
    }
  };
};

export const updNotePasswordDone = permission => {
  return { type: __.UPDATE_NOTE_PASSWORD_DONE, payload: { permission } };
};

export const mergeNote = (uId, ids) => {
  const url = `/api/v1/users/${uId}/notes/_merge`;

  const body = {
    ids,
  };

  return async dispatch => {
    try {
      dispatch({ type: __.MERGE_NOTE });
      const res = await postRequest(url, body);
      const data = await res.data;
      const nId = data.payload.id;

      const fchUrl = `/api/v1/users/${uId}/notes/${nId}`;
      const noteResponse = await getRequest(fchUrl);
      const noteData = await noteResponse.data;
      dispatch(mergeNoteDone(noteData));
      return noteResponse;
    } catch (error) {
      console.log(error);
    }
  };
};

export const mergeNoteDone = doc => {
  return { type: __.MERGE_NOTE_DONE, payload: { doc } };
};

export const shareNote = (note, sType, labelList, view) => {
  return async dispatch => {
    try {
      const doc = note;

      const url = doc.url && `連結：${doc.url}\n\n`;
      const title = `標題：${doc.title}\n\n`;
      const labels =
        doc.labels.length > 0 &&
        labelList &&
        `標籤：${labelList
          .filter(item => doc.labels.includes(item.id))
          .map(item => item.name)
          .join(", ")}\n\n`;
      const tags = doc.tags && `關鍵字：${doc.tags.join(", ")}\n\n`;
      const content = `內容：${doc[view]}`;

      const text = `${url}${title}${labels}${tags}${content}`;

      switch (sType) {
        case "LINE":
          window.open(
            `https://line.me/R/share?text=${encodeURIComponent(text)}`,
            "_self",
            "noreferrer"
          );
          break;
        case "HTML":
          const html = genHTML(doc, view);
          navigator.clipboard.writeText(html);
          break;
        case "TXT":
          genTxt(doc, view);
          break;
        case "MARKDOWN":
          const markdown = genMarkDown(doc, view);
          navigator.clipboard.writeText(markdown);
          break;
        case "PC":
          navigator.clipboard.writeText(text);
          break;
        default:
          break;
      }
      dispatch({ type: __.SHARE_NOTE });
    } catch (error) {
      console.log(error);
    }
  };
};

export const cngPwdModalOpen = open => {
  return { type: __.CNG_PWD_MODAL, payload: { open } };
};

export const uploadFile = (file, uId) => {
  return async dispatch => {
    try {
      dispatch({ type: __.UPD_FILE });
      const formData = new FormData();
      formData.append("file", file);
      const res = await postRequest("/files?namespace=files", formData);
      const { payload } = await res.data;
      const fileData = {
        type: "file",
        file: {
          url: payload.path,
        },
      };

      const createRes = await postRequest(
        `/api/v1/users/${uId}/notes`,
        fileData
      );
      const data = await createRes.data;
      await dispatch(uploadFileDone());
      return data.payload.id;
    } catch (error) {
      console.log(error);
    }
  };
};

export const uploadFileDone = () => {
  return { type: __.UPD_FILE_DONE };
};

export const summaryNote = (uid, nid) => {
  const url = `/api/v1/users/${uid}/notes/${nid}/_summary`;

  return async dispatch => {
    dispatch({ type: __.SUMMARY_NOTE });
    const res = await postRequest(url);
    const data = await res.data;
    return data;
  };
};

export const summaryNoteDone = note => {
  return { type: __.SUMMARY_NOTE_DONE, payload: { note } };
};

export const translateNote = (uid, nid, language) => {
  const url = `/api/v1/users/${uid}/notes/${nid}/_translate`;

  const body = {
    language,
  };

  return async dispatch => {
    dispatch({ type: __.TRANSLATE_NOTE });
    const res = await postRequest(url, body);
    const data = await res.data;
    dispatch({ type: __.TRANSLATE_NOTE_DONE, payload: { note: data.payload } });
    return data;
  };
};

export const translateNoteDone = note => {
  return { type: __.TRANSLATE_NOTE_DONE, payload: { note } };
};

export const polishNote = (uid, nid) => {
  const url = `/api/v1/users/${uid}/notes/${nid}/_polish`;

  return async dispatch => {
    dispatch({ type: __.POLISH_NOTE });
    const res = await postRequest(url);
    const data = await res.data;
    dispatch({ type: __.POLISH_NOTE_DONE, payload: { note: data.payload } });
    return data;
  };
};

export const polishNoteDone = note => {
  return { type: __.POLISH_NOTE_DONE, payload: { note } };
};

export const cngNoteView = (nId, view) => {
  return { type: __.CNG_NOTE_VIEW, payload: { nId, view } };
};

export const clearNote = () => {
  return { type: __.CLEAR_NOTE };
};

export const generateAIContent = (uid, nid, content_type, signalKey = "") => {
  return async dispatch => {
    try {
      const type = content_type.split("-")[0];
      const index = content_type.split("-")[1];

      dispatch({
        type: __.SEND_STREAM_REQUEST,
        payload: { type },
      });

      dispatch(cngNoteView(nid, `generated-${index}`));

      const url = `/api/v1/users/${uid}/notes/${nid}/_generate_content`;

      const handleStreamData = chunk => {
        dispatch({
          type: __.RECEIVE_STREAM_DATA,
          payload: { chunk },
        });
      };

      await streamRequest(
        url,
        { content_type: type, stream: true },
        handleStreamData,
        signalKey
      );
      dispatch({
        type: __.RECEIVE_STREAM_DATA_DONE,
      });
    } catch (error) {
      console.error("Generate AI content error:", error);
      // dispatch({ type: __.RECEIVE_STREAM_DATA_ERROR, payload: error });
    } finally {
      dispatch({ type: __.STOP_STREAM_REQUEST });
      await stopStream(signalKey);
    }
  };
};

export const stopStreamRequest = signalKey => {
  return async dispatch => {
    await stopStream(signalKey);
    dispatch({ type: __.STOP_STREAM_REQUEST });
  };
};
