import Immutable from 'immutable';

import * as __ from '../types/note';
import { FCH_NOTE } from '../types/search';
import { CREATE_LABEL, CREATE_LABEL_DONE } from '../types/label';

const initialState = Immutable.Map({
  isGenerating: false,
  isWaiting: false,
  config: {
    listView: true,
    pwdModalOpen: false,
    view: "content",
  },
  selectedNotes: [],
  saveStatus: '',
});

const noteReducer = (_ = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FCH_NOTE:
    case __.CLEAR_STATUS:
      return _.set('saveStatus', '');
    case CREATE_LABEL:
    case __.UPDATE_NOTE_LABEL:
    case __.UPDATE_NOTE_CONTENT:
    case __.UPDATE_NOTE_TITLE:
    case __.UPDATE_NOTE_URL:
      return _.set('saveStatus', 'SAVING');
    case CREATE_LABEL_DONE:
    case __.UPDATE_NOTE_CONTENT_DONE:
    case __.UPDATE_NOTE_LABEL_DONE:
    case __.UPDATE_NOTE_TITLE_DONE:
    case __.UPDATE_NOTE_URL_DONE:
      return _.set('saveStatus', 'DONE');
    case __.SEL_NOTE:
      const { id, checked } = payload;
      const selectedNotes = _.get('selectedNotes');
      if (checked) {
        return _.set('selectedNotes', [...selectedNotes, id]);
      } else {
        return _.set(
          'selectedNotes',
          selectedNotes.filter(item => item !== id)
        );
      }
    case __.CLEAR_NOTES:
      return _.set('selectedNotes', []);
    case __.DEL_NOTE_LABEL_DONE:
      return _.setIn(
        ['results', 'labels'],
        _.getIn(['results', 'labels']).filter(label => label.id !== payload.lId)
      );
    case __.CNG_LIST_VIEW:
      return _.setIn(['config', 'listView'], payload.listView);
    case __.CNG_PWD_MODAL:
      return _.setIn(['config', 'pwdModalOpen'], payload.open);
    case __.SEND_STREAM_REQUEST:
      return _.set('isWaiting', true);
    case __.RECEIVE_STREAM_DATA:
      return _.set('isWaiting', false).set('isGenerating', true);
    case __.RECEIVE_STREAM_DATA_DONE:
      return _.set('isGenerating', false);
    case __.STOP_STREAM_REQUEST:
      return _.set('isWaiting', false).set('isGenerating', false);
    default:
      return _;
  }
};

export default noteReducer;
