export const FCH_USER = "USER/FCH_USER";
export const FCH_USER_DONE = "USER/FCH_USER_DONE";

export const FCH_TOKEN = "USER/FCH_TOKEN";
export const FCH_TOKEN_DONE = "USER/FCH_TOKEN_DONE";

export const LINE_LOGIN = "USER/LINE_LOGIN";
export const LINE_LOGIN_SUCCESS = "USER/LINE_LOGIN_SUCCESS";
export const LINE_LOGIN_FAILURE = "USER/LINE_LOGIN_FAILURE";

export const EMAIL_LOGIN = "USER/EMAIL_LOGIN";
export const LOGIN_SUCCESS = "USER/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USER/LOGIN_FAILURE";

export const EMAIL_SIGNUP = "USER/EMAIL_SIGNUP";
export const SIGNUP_SUCCESS = "USER/SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "USER/SIGNUP_FAILURE";

export const UPD_LANG = "USER/UPD_LANG";
export const UPD_LANG_DONE = "USER/UPD_LANG_DONE";

export const UPD_CONTENT_LANG = "USER/UPD_CONTENT_LANG";
export const UPD_CONTENT_LANG_DONE = "USER/UPD_CONTENT_LANG_DONE";

export const REDEEM_CODE = "USER/REDEEM_CODE";
export const REDEEM_CODE_DONE = "USER/REDEEM_CODE_DONE";

export const USER_LOGOUT = "USER/USER_LOGOUT";

export const FORGET_PASSWORD = "USER/FORGET_PASSWORD";
export const FORGET_PASSWORD_DONE = "USER/FORGET_PASSWORD_DONE";
