import React from "react";

import UserManagementPage from "app/pages/user/management";

const userRoutes = [
  {
    path: "/private/dXNlci9tYW5hZ2VtZW50",
    element: <UserManagementPage />,
  },
];

export default userRoutes;
