import Div from "@jumbo/shared/Div";
import { Box, IconButton, Link, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import HomeIcon from "@mui/icons-material/Home";
import { shareNote } from "app/redux/actions/note";
import queryString from "query-string";

const ViewHeader = () => {
  const dispatch = useDispatch();

  const view = queryString.parse(window.location.search).type;

  const handleShare = () => {
    dispatch(shareNote(note, "TXT", null, view));
  };

  const note = useSelector(({ search }) => search.getIn(["results", "note"]));

  if (!note) return null;

  return (
    <Div
      id="note-page-header"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
      }}>
      <Box
        component="div"
        sx={{
          display: { md: "flex", xs: "none" },
          flexDirection: "row",
          alignItems: "center",
        }}>
        <img
          src="/images/logo-main.png"
          alt="logo"
          style={{ width: "64px", height: "64px" }}
        />
        <Typography
          variant="h1"
          color="#fff"
          sx={{ position: "relative", top: 4 }}>
          AIMochi
        </Typography>
      </Box>

      <Stack
        component="div"
        sx={{ position: "absolute", right: 0 }}
        gap={2}
        width="100%"
        alignItems="center"
        justifyContent={{ md: "flex-end", xs: "space-between" }}
        flexDirection="row">
        <Stack flexDirection="column">
          <Typography variant="h2" color="#fff" sx={{ mb: 0.2 }}>
            AI<strong>Mochi</strong>
            <span style={{ color: "#2FAEC6" }}>.</span>
          </Typography>
          <Typography
            variant="h3"
            color="#E7D811"
            sx={{
              fontWeight: 700,
              mb: 0.2,
              fontSize: { md: "1.5rem", xs: "0.8rem" },
            }}>
            分享給還在聽打逐字稿的同事們！
          </Typography>
          <Typography
            variant="body1"
            component="span"
            color="#fff"
            sx={{ fontSize: { md: "1rem", xs: "0.6rem" } }}>
            AI語音轉文字，
            <Typography
              component="span"
              variant="body1"
              sx={{
                fontSize: { md: "1rem", xs: "0.7rem" },
                color: "#E7D811",
                padding: "3px 0px",
                borderBottom: {
                  md: "2px solid #e7d811",
                  xs: "1px solid #e7d811",
                },
              }}>
              自動完成會議記錄
            </Typography>
            。
          </Typography>
        </Stack>
        <Stack flexDirection="row" gap={0.5}>
          <IconButton
            onClick={() =>
              window.open(
                "https://liff.line.me/1645278921-kWRPP32q/?accountId=aimochi",
                "_blank"
              )
            }
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.086) 0px 5px 10px",
              color: "#475259",
              width: { md: "3.5rem", xs: "2.5rem" },
              height: { md: "3.5rem", xs: "2.5rem" },
            }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { md: 18, xs: 11 },
                position: "relative",
                top: 2,
              }}>
              安裝
            </Typography>
          </IconButton>

          <IconButton
            onClick={handleShare}
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.086) 0px 5px 10px",
              width: { md: "3.5rem", xs: "2.5rem" },
              height: { md: "3.5rem", xs: "2.5rem" },
            }}>
            <DownloadIcon
              sx={{
                fontSize: { md: "36px", xs: "30px" },
                transform: "scaleX(-1)",
                cursor: "pointer",
                color: "#475259",
              }}
            />
          </IconButton>

          <IconButton
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.086) 0px 5px 10px",
              width: { md: "3.5rem", xs: "2.5rem" },
              height: { md: "3.5rem", xs: "2.5rem" },
            }}>
            <Link href="https://www.aimochi.ai/" target="_blank">
              <HomeIcon
                sx={{
                  fontSize: { md: "36px", xs: "30px" },
                  transform: "scaleX(-1)",
                  cursor: "pointer",
                  color: "#475259",
                }}
              />
            </Link>
          </IconButton>
        </Stack>
      </Stack>
    </Div>
  );
};

export default ViewHeader;
