import React, { useState } from "react";
import {
  Modal,
  Fade,
  Divider,
  Typography,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Backdrop from "@mui/material/Backdrop";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { forgetPassword } from "app/redux/actions/auth";

const ForgetPasswordModal = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("請輸入正確的信箱格式")
      .required("信箱是必填欄位"),
  });

  const handleSubmit = values => {
    const { email } = values;
    dispatch(forgetPassword(email));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={isOpen}>
          <form onSubmit={formik.handleSubmit}>
            <Div
              sx={{
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                pb: 2,
              }}>
              <Typography
                id="transition-modal-title"
                variant="h1"
                sx={{ fontWeight: 700 }}>
                忘記密碼
              </Typography>
              <Divider sx={{ my: 3 }} />
              <Stack direction="column" spacing={2}>
                <TextField
                  name="email"
                  fullWidth
                  label="信箱"
                  placeholder="請輸入信箱以重設密碼"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Stack>
              <Divider sx={{ mt: 3, mb: 2 }} />
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit">
                  確定
                </Button>
                <Button variant="contained" color="error" onClick={handleClose}>
                  取消
                </Button>
              </Stack>
            </Div>
          </form>
        </Fade>
      </Modal>
      <Button fullWidth onClick={handleOpen} sx={{ mb: 1 }}>
        忘記密碼
      </Button>
    </>
  );
};

export default ForgetPasswordModal;
