import React, { useState } from "react";
import {
  Modal,
  Fade,
  Divider,
  Typography,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import Backdrop from "@mui/material/Backdrop";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { createUser } from "app/redux/actions/user";

const CreateUserModal = () => {
  const dispatch = useDispatch();

  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("請輸入正確的信箱格式")
      .required("信箱是必填欄位"),
    password: yup.string().required("密碼是必填欄位"),
    confirmPassword: yup
      .string()
      .required("密碼確認是必填欄位")
      .oneOf([yup.ref("password"), null], "密碼確認與密碼不一致"),
  });

  const handleSubmit = values => {
    const { email, password } = values;
    dispatch(createUser({ email, password }));
    handleClose();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={isOpen}>
          <form onSubmit={formik.handleSubmit}>
            <Div
              sx={{
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                pb: 2,
              }}>
              <Typography
                id="transition-modal-title"
                variant="h1"
                sx={{ fontWeight: 700 }}>
                新增使用者
              </Typography>
              <Divider sx={{ my: 3 }} />
              <Stack direction="column" spacing={2}>
                <TextField
                  name="email"
                  fullWidth
                  label="信箱"
                  placeholder="請輸入信箱"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  name="password"
                  fullWidth
                  label="密碼"
                  placeholder="請輸入密碼"
                  type={isShowPassword ? "text" : "password"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setIsShowPassword(!isShowPassword)}>
                        {isShowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                <TextField
                  name="confirmPassword"
                  fullWidth
                  label="密碼確認"
                  placeholder="請再次輸入密碼"
                  type={isShowConfirmPassword ? "text" : "password"}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setIsShowConfirmPassword(!isShowConfirmPassword)
                        }>
                        {isShowConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Stack>
              <Divider sx={{ mt: 3, mb: 2 }} />
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit">
                  確定
                </Button>
                <Button variant="contained" color="error" onClick={handleClose}>
                  取消
                </Button>
              </Stack>
            </Div>
          </form>
        </Fade>
      </Modal>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        新增使用者
      </Button>
    </>
  );
};

export default CreateUserModal;
